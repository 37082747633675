import { j as r } from "./jsx-runtime-B6kdoens.js";
import "./Accordion-ZP3ZPh2g.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import "./Button-DhTfdjdw.js";
import "./DropdownMenu-D5Lc6MaH.js";
import { I as l } from "./Icon-VZwz_Tkb.js";
import "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import { T as m } from "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import "react";
import "./Stack-9Jnn74Nm.js";
import "./Switch-Cnw0_Ap0.js";
import "./Toast-BARhJGai.js";
import "./Toggle-DH_2vm0p.js";
const $ = (o) => {
  const a = s.map((e) => e.regex.test(o.password));
  return a.every((e) => e) || !o.password ? null : /* @__PURE__ */ r.jsxs("div", { className: "bg-white p-4 rounded-md shadow-xl text-left  border border-gray-300", children: [
    /* @__PURE__ */ r.jsx(
      m,
      {
        variant: "black-primary",
        weight: "normal",
        className: "pb-2",
        children: "Password must have"
      }
    ),
    s.map((e, t) => /* @__PURE__ */ r.jsxs("div", { id: `rule-${t}`, "data-testid": "rule", className: `
                ${a[t] ? "text-green-1" : "text-red-200"}
                border-b border-gray-10 py-2 flex items-center gap-2
                ${t === s.length - 1 ? "border-b-0" : ""}
                `, children: [
      /* @__PURE__ */ r.jsx(
        l,
        {
          name: a[t] ? "Check" : "Cancel",
          width: 24,
          strokeWidth: 1.5,
          className: "mr-1"
        }
      ),
      /* @__PURE__ */ r.jsx(
        m,
        {
          variant: "inherit",
          weight: "normal",
          size: "sm",
          children: e.name
        }
      )
    ] }, e.name))
  ] });
}, s = [
  {
    name: "At least 8 characters",
    regex: /.{8,}/
  },
  {
    name: "At least one uppercase letter ABC..",
    regex: /[A-Z]/
  },
  {
    name: "At least one lowercase letter abc..",
    regex: /[a-z]/
  },
  {
    name: "At least one number 123..",
    regex: /[0-9]/
  },
  {
    name: "At least one special character &$#%*",
    regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
  }
];
export {
  $ as default
};
