
import Avatar from 'components/dist/atoms/Avatar';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';


export const AppStartupScreen = () => {

    return <Stack
        justify="center"
        space="lg"
        className='w-dvw h-dvh bg-black-10 z-dialog inset-0 absolute items-center pt-20'>
        <Avatar size="xl" className='shadow-xl'>
            <Avatar.Image src='/static/apple-icon-120x120.png' alt="Logo" />
            <Avatar.Fallback>
                MS
            </Avatar.Fallback>
        </Avatar>
        <Stack space="lg" className='flex flex-col flex-1 items-center justify-center'>

            <div className='w-10 h-10 aspect-square border-4 border-t-transparent animate-spin border-blue-100 rounded-full' />
            <Text>
                Application is starting and will be ready in a few minutes...
            </Text>
        </Stack>
    </Stack>
}