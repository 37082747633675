import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Text from 'components/dist/atoms/Text';

type Props = {
    children?: JSX.Element;
}

export const TermsAndConditions: React.FC<Props> = (props) => {
    return (<Dialog  >
        <Dialog.Trigger asChild>
            <Text className="underline-offset-4 cursor-pointer" underline size="sm" variant="blue" as="a">
                Terms and Conditions
            </Text>
        </Dialog.Trigger>
        <Dialog.Content
            className='sm:max-w-7xl	h-full' aria-labelledby="scroll-dialog-title">
            <Text weight="medium" className="px-3 pt-3" id="scroll-dialog-title">Platform License Agreement and Terms of Service</Text>
            <Dialog.Close asChild>
                <Button className="absolute top-0 right-0 bg-transparent border-none shadow-none p-0 inline-flex items-center justify-center text-gray-500 hover:bg-transparent hover:text-gray-500">
                    <Icon
                        name="Cancel"
                        width={24}
                        className="mr-1"
                    />
                </Button>
            </Dialog.Close  >
            <Separator />
            <div className='overflow-hidden flex-1'>
                <iframe
                    title="TOS and PLA"
                    className="border-0 px-4 w-full h-full lg:min-h-[800px] overflow-auto scrollbar-stable"
                    src='/static/pla.html' />
            </div>
        </Dialog.Content>
    </Dialog>
    );
}
